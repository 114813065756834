import { Accordion } from "react-bootstrap";
import DynamicDrinksList from "./DynamicDrinkList";

const DrinksList = () => {
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Drinks List</h2>
      <Accordion defaultActiveKey="0" flush alwaysOpen>
        <DynamicDrinksList
          name="On Cask"
          src="data/casks.csv"
          accoridanKey="0"
        />
        <DynamicDrinksList name="On Keg" src="data/kegs.csv" accoridanKey="1" />
        <DynamicDrinksList
          name="Spirits Bar"
          src="data/other.csv"
          accoridanKey="2"
        />
        <DynamicDrinksList
          name="Snacks"
          src="data/snacks.csv"
          accoridanKey="3"
        />
      </Accordion>
    </div>
  );
};

export default DrinksList;
