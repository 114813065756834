import { useEffect, useState } from "react";
import Papa from "papaparse";
import { drink } from "./types";
import DrinksListItem from "./DrinksListItem";
import { Accordion } from "react-bootstrap";

type props = {
  src: string;
  accoridanKey: string;
  name: string;
};

const convertToDrinkArr = (parsedResult: Papa.ParseResult<any>): drink[] => {
  let result: drink[] = [];
  parsedResult.data.shift();
  parsedResult.data.map((row) => {
    let drink: drink = {
      menuNum: row[0],
      title: row[1],
      sponsor: row[2],
      notes: row[3],
      desc: row[4],
      brewery: row[5],
    };
    result.push(drink);
  });
  return result;
};

const DynamicDrinksList = (props: props) => {
  const [drink, setDrinks] = useState<drink[]>([]);

  useEffect(() => {
    fetch(props.src)
      .then((response) => response.text())
      .then((responseText) => {
        const parsedData = Papa.parse<any>(responseText);
        setDrinks(convertToDrinkArr(parsedData));
      });
  }, []);

  return (
    <Accordion.Item
      eventKey={props.accoridanKey}
      style={{ backgroundColor: "#1d1d1a" }}
    >
      <Accordion.Header style={{ backgroundColor: "#1d1d1a" }}>
        {props.name}
      </Accordion.Header>
      <Accordion.Body>
        {drink?.length > 1 ? (
          drink.map((cask, i) => {
            return <DrinksListItem drink={cask} key={i} />;
          })
        ) : (
          <span style={{ color: "white" }}>Nothing to show 😢</span>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default DynamicDrinksList;
